import React, { useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams} from "react-router";
import { PDFDocument, TextAlignment } from "pdf-lib";
import { Document, Page } from "react-pdf";
import {PDFDownloadLink, pdf, StyleSheet, Image, Text, PDFViewer} from "@react-pdf/renderer";
import {API_URL} from "./constants";
import axios from "axios";
import { pdfjs } from 'react-pdf';
import { create } from "@mui/material/styles/createTransitions";

import coitbbadge from "../src/Assets/coitbbadges/coitb-badge.png"
import { Style } from "@mui/icons-material";


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const options = {
    standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
    cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
};


export default function CertGeneration(props){
    const location = useLocation();
    const id = useParams();
    const [modifiedPDFBytes, setModifiedPDFBytes] = useState([]);
    const [downloadBytes, setDownloadBytes] = useState([]);
    const [createdPDF, setCreatedPDF] = useState();

    function download() {
        const blob = new Blob([downloadBytes], { type: "application/pdf;charset=utf-8" });
        const url = URL.createObjectURL(blob);
        window.open(url);
    }

    const styles = StyleSheet.create({
        Image: {
            width:200,
            height:200,
            
        },
    })

    useEffect(()=> {
        
        //get the certName from the db based on the certid
        async function fetchData(){
            let bytes;
            let pdfDoc;
            let courseid = parseInt(id.courseid) || 0;
            //Fetch from erasolutions api and return the document itself instead of locally
            //updated to fetch based on course if it exists
            if(courseid > 0){
                bytes = await fetch(`${API_URL}/loadcoursecert/${courseid}`).then(res => res.arrayBuffer());
                let UintBytes = new Uint8Array(bytes);
                pdfDoc = await PDFDocument.load(UintBytes);

            } else {
                bytes = await fetch(`${API_URL}/loadbasecert`).then(res => res.arrayBuffer());
                let UintBytes = new Uint8Array(bytes);
                pdfDoc = await PDFDocument.load(UintBytes);
            }
            
            if(pdfDoc){  
                // Get the form containing all the fields
                const form = pdfDoc.getForm(); 
                let fullname = `${location.state.FirstName} ${location.state.LastName}`;
                let date = new Date(location.state.CertDate);
                let expirationDate = new Date(location.state.CertDate);//date.setFullYear(date.getFullYear() + 3); 
                expirationDate.setFullYear(expirationDate.getFullYear() + 3);

                try{
                    if(courseid > 0){
                         // Get the form Fields and set the values
                        let nameField = form.getTextField("name");
                        let dateField = form.getTextField("certificationDate");
                        let certField = form.getTextField("certificationID");
                        let expirationField = form.getTextField("expirationDate");
                        //let certName = form.getTextField("Text5");

                        nameField.setText(fullname);
                        nameField.setAlignment(TextAlignment.Center);
                        nameField.setFontSize(24);

                        dateField.setText(date.toLocaleDateString('en-US'));
                        dateField.setFontSize(11);
            
                        expirationField.setText(expirationDate.toLocaleDateString('en-US'));
                        expirationField.setFontSize(11);
                        
                        certField.setText(String(location.state.CertID));
                        certField.setFontSize(11);

                    }
                    else
                    {

                        // Get the form Fields and set the values
                        const nameField = form.getTextField("Text1");
                        const dateField = form.getTextField("Text2");
                        const certField = form.getTextField("Text3");
                        const codeField = form.getTextField("Text4");
                        const certName = form.getTextField("Text5");

                        //set values for the form fields
                        nameField.setText(fullname);
                        dateField.setText(date.toLocaleDateString('en-US'));
                        certName.setText(String(location.state.Cert));
                        certField.setText(String(location.state.CertID));
                        //code field might not be present on all certifications

                        try{
                            codeField.setText(String(location.state.Guid));
                        }
                        catch(ex){
                            console.log(ex);
                        }
                    }

                    form.flatten();
            
                    // Save the modified document as a new PDF file
                    const pdfBytes = await pdfDoc.save();
                    setModifiedPDFBytes( new Uint8Array(pdfBytes));
                    setDownloadBytes(new Uint8Array(pdfBytes));
                    setCreatedPDF(await PDFDocument.load(modifiedPDFBytes));
                }
                catch(e){
                    console.log("PDF Doc Load Error: ", e);
                }
            }
            else
            {
                console.log("ERROR");
            }
        }

        fetchData();

    },[])

    const Certificate = () => (
        <>
            <Document options={options} file={{data: modifiedPDFBytes}} className="pdf"
                      loading="Please wait for PDF to Generate" noData="The PDF has not been loaded correctly."
                      onLoadError={(error) => alert(error.message)} renderMode="canvas">
                <Page pageNumber={1} renderAnnotationLayer={false} renderTextLayer={false}>
                </Page>
            </Document>
        </>
    )
    return (
        <div style={{
            marginTop: "10%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <div style={{maxHeight: "800px", padding: "0px"}}>
                {modifiedPDFBytes.length > 0 ? (<Certificate/>) :
                    <h3>Loading Certification...</h3>}
            </div>
            <button style={{maxHeight: 'fit-content', fontSize: "x-large"}}
                    onClick={download}>Download PDF
            </button>
        </div>
    )
}
